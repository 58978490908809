<template>
  <div>
    <b-form-group
      class="required"
      label="Mã lĩnh vực"
      label-for="maLinhVuc"
    >
      <validation-provider
        v-slot="{ errors }"
        key="maLinhVuc"
        :rules="{ required: true }"
        name="maLinhVuc"
      >
        <b-form-input
          id="maLinhVuc"
          ref="maLinhVuc"
          v-model="dataForm.maLinhVuc"
          v-trim-input
          type="text"
          :class="{required: errors.length > 0}"
          :autofocus="dataForm.id ? false : true"
          placeholder="Nhập mã lĩnh vực"
        />
        <span class="text-danger">{{ errors[0] }}</span>
        <span class="text-danger">{{ errorTrungMa }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      class="required"
      label="Tên lĩnh vực"
      label-for="tenLinhVuc"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenLinhVuc"
        :rules="{ required: true }"
        name="tenLinhVuc"
      >
        <b-form-input
          id="tenLinhVuc"
          ref="tenLinhVuc"
          v-model="dataForm.tenLinhVuc"
          v-trim-input
          type="text"
          :class="{required: errors.length > 0}"
          placeholder="Nhập tên lĩnh vực"
        />
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
    >
      <b-form-checkbox
        v-model="dataForm.status"
        switch
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Ghi chú"
      label-for="input-1"
    >
      <b-form-textarea
        id="textarea"
        v-model="dataForm.ghiChu"
        v-trim-input
        placeholder="Nhập ghi chú"
        rows="3"
        max-rows="6"
      />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
}
</script>
