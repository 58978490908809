<template>
  <linh-vuc />
</template>
<script>
import LinhVuc from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/LinhVuc.vue'

export default {
  components: {
    LinhVuc,
  },
}
</script>
