<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'ayncBtn' , 'reFresh']"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
      @async-data="asyncData"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
      @update-item="updateCommom"
      @delete-item="deleteCommomTable"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <span v-if="props.props.column.field == 'status'">
          <treeselect
            v-model="payload.status"
            v-format-v-select
            class="vtreeselect-chooser"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            :options="optionsTrangThai"
            placeholder="Chọn trạng thái"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
        </span>
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
import XoaForm from '@/modules/danh-muc/components/form/XoaForm.vue'
import LinhVucForm from '@/modules/danh-muc/components/form/LinhVucForm.vue'
import {
  BCard,
  BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import { checkStatus, compareObjects } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'
import { checDeleteMultiple } from '../../../../utils/common-utils'

export default {
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    GoodTable,
    BButton,
    XoaForm,
    LinhVucForm,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới lĩnh vực',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã lĩnh vực',
          field: 'maLinhVuc',
          width: '180px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên lĩnh vực',
          field: 'tenLinhVuc',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '150px',
          tdClass: 'text-left',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center',
          thClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      optionsTrangThai: STATUS,
      selectedItems: [],
      isShow: false,
      isShowFooter: false,
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        maLinhVuc: null,
        tenLinhVuc: null,
        ghiChu: null,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        maLinhVuc: null,
        tenLinhVuc: null,
        ghiChu: null,
        status: true,
      },
      isLoading: false,
      size: null,
      beginObject: {},
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.LINH_VUC.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.LINH_VUC.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.LINH_VUC.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataLinhVuc()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    getDataLinhVuc() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.LINH_VUC.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới lĩnh vực'
      this.componentName = 'LinhVucForm'
      this.size = 'lg'
      this.dataForm = {
        maLinhVuc: null,
        tenLinhVuc: null,
        ghiChu: null,
        status: true,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.LINH_VUC.XOA, payload, false).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataLinhVuc()
            this.$refs.commonModal.hideModal()
            checDeleteMultiple(this, res.data)
          }
        })
      } else if (this.componentName === 'LinhVucForm') {
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.LINH_VUC.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataLinhVuc()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        } else {
          this.$axios.post(END_POINTS.LINH_VUC.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataLinhVuc()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật lĩnh vực'
      this.componentName = 'LinhVucForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        maLinhVuc: data.maLinhVuc,
        tenLinhVuc: data.tenLinhVuc,
        ghiChu: data.ghiChu,
        status: data.status,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa lĩnh vực đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa lĩnh vực đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.LINH_VUC.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        maLinhVuc: data?.maLinhVuc ? data?.maLinhVuc.replace(/\s+/g, ' ').trim() : null,
        tenLinhVuc: data?.tenLinhVuc ? data?.tenLinhVuc.replace(/\s+/g, ' ').trim() : null,
        status: this.payload.status,
        ghiChu: data?.ghiChu ? data?.ghiChu : null,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.LINH_VUC.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
    asyncData() {
      this.$axios.post(END_POINTS.LINH_VUC.DONG_BO, {}, false).then(res => {
        if (res.data.code === 200) {
          this.getDataLinhVuc()
          this.$toast.success(res.data.message)
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    resetFilter() {
      this.payload = {
        maLinhVuc: null,
        tenLinhVuc: null,
        ghiChu: null,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataLinhVuc()
      })
    },
    refreshPage() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.getDataLinhVuc()
    },
  },
}
</script>
